import React from "react"
import Header from "../../components/header/header"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ImageGallery from "../../components/gallery/gallery-component"
import { sAndAphotos } from "../../components/photos/s-a-photos"
import "../../../styles/global-styles.scss"

const SarahAndAli = () => (
  <Layout>
    <Header />
    <SEO
      title="Sarah & Ali"
      description="The YES micro wedding is a one-of-a-kind experience for friends and family to celebrate this monumental day. Our top-notch staff will ensure that you have the best day ever as they guide you through each step of the planning process and ensure that your vision becomes a reality."
    />
    <h1 className="gallery-photos-heading">Sarah & Ali</h1>
    <p className="gallery-photos-text-body">
      Sarah & Ali's civil ceremony in downtown Toronto, featuring the Allan
      Gardens. This was a YES micro wedding, a one-of-a-kind experience for
      friends and family to celebrate this monumental day. Our top-notch staff
      will ensure that you have the best day ever as they guide you through each
      step of the planning process and ensure that your vision becomes a
      reality.
    </p>
    <ImageGallery photos={sAndAphotos} />
  </Layout>
)

export default SarahAndAli
