export const sAndAphotos = [
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0002.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0005.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0006.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0007.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0008.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0009.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0012.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0016.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0018.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0020.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0021.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0022.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0023.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0024.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0025.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0026.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0027.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0028.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0032.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0033.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0035.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0036.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0038.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0039.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0047.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0048.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0049.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0050.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0053.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0055.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0056.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0058.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0060.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0061.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0062.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0063.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0064.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0071.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0074.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0076.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0079.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0080.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0083.jpg",
    width: 3,
    height: 4,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0084.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0088.jpg",
    width: 4,
    height: 3,
  },
  {
    src:
      "https://yesinspiredgallery.s3.us-east-2.amazonaws.com/Sarah+%26+Ali/IMG-20201205-WA0091.jpg",
    width: 4,
    height: 3,
  },
]
